import React from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

import Layout from '../components/layout'
import SEO from "../components/seo"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default props => {

  return (
    <Layout>
      <SEO />
      <div className="container mx-auto text-center mx-8 my-24"><FormattedMessage id="site.404.text" /><br /> <Link to="/" className="link"><FormattedMessage id="site.404.link" /> &rarr;</Link></div>
      <div className="w-full h-screen bg-img-12 bg-cover">

      </div>


    </Layout>
  )
}